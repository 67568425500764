/* TODO: Temporary, need to scope this to the component in a sensible way */
/* * {
  font-family: Roboto, sans-serif;
} */
/* 
body {
  margin: 0px;
  padding: 0;
} */

.st_pagewrapper {
  margin-top: 1rem;
}

a {
  text-decoration: none;
}

/* 
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn-secondary {
  color: #6c757d !important;
  border-color: #6c757d !important;
  background: transparent !important;
}

.btn-secondary:hover {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.btn-light:hover {
  color: #212529;
  background-color: #f8f9fa !important;
  border-color: #ced4da !important;
}

.btn-light {
  color: #212529;
  background-color: #e9ecef !important;
  border-color: #ced4da !important;
}
 */

/* .breadcrumb {
  margin-bottom: 1rem!important;
  background-color: transparent!important;
  border-radius: 0em!important;
  border-bottom: solid lightgrey 0.01em!important;
  padding-left: 0em!important;
} */
